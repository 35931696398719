import { screenBreakPoint } from "@styles/styleVariables";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import styled from "styled-components";
import { EventSection } from "./eventSection";
import { motion } from "framer-motion";
import { parentAnimation } from "@styles/animations";

export const EventContent = () => {
  const query = useStaticQuery(graphql`
    query eventInformationQuery {
      eventInformationQuery: allStrapiEventInformation(
        sort: { fields: startDateTime, order: ASC }
      ) {
        nodes {
          briefDescription
          strapi_id
          name
          useTemplate
          rank
          publishedAt
          startDateTime
          endDateTime
          sublink
          registrationForm
        }
      }
    }
  `);

  const eventInformationQuery = query.eventInformationQuery.nodes;

  return (
    <SEventContent
      initial="before"
      whileInView="after"
      variants={parentAnimation(0.1)}
    >
      {eventInformationQuery?.map((mp: any) => (
        <EventSection
          name={mp?.name ?? ""}
          briefDescription={mp?.briefDescription ?? ""}
          strapiId={mp?.strapi_id ?? ""}
          registrationForm={mp?.registrationForm}
          buttonName={mp?.sublink?.name ?? ""}
          useTemplate={mp?.useTemplate ?? false}
          rank={mp?.rank ?? "9999"}
          publishedAt={mp?.publishedAt ?? "01/01/2001"}
          endDateTime={mp?.endDateTime ?? ""}
          startDateTime={mp?.startDateTime ?? ""}
        ></EventSection>
      ))}
    </SEventContent>
  );
};

const SEventContent = styled(motion.div)`
  min-height: 50vh;
  width: 100%;
  padding: 2rem 1rem;

  @media (max-width: ${screenBreakPoint.sm}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
