import { IEventCard, IInformationSection } from "@interface/propsInterface";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { pushItemUp } from "@styles/animations";
import { BrandButton } from "@components/reusables/brandButton";
import { ButtonEnum } from "src/enums/buttonsEnum";

export const EventSection = ({
  name,
  briefDescription,
  strapiId,
  publishedAt,
  startDateTime,
  endDateTime,
  registrationForm,
}: IEventCard) => {
  let startDate = new Date(startDateTime).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  let endDate = new Date(endDateTime).toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });

  return (
    <SEventCard variants={pushItemUp} whileInView="after" initial="before">
      <h1 className="event-card-title">{name ?? ""}</h1>
      <div className="event-card-details">
        <p className="event-card-dates">
          {startDate} to {endDate}
        </p>
        <p className="event-card-description">{briefDescription ?? ""}</p>
      </div>
      {registrationForm && (
        <div className="event-card-registration">
          <BrandButton
            link={registrationForm}
            name="Register Now!"
            id={registrationForm}
            category={ButtonEnum.Tertiary}
          ></BrandButton>
        </div>
      )}
    </SEventCard>
  );
};

const SEventCard = styled(motion.div)`
  margin: 1rem;
  width: 100%%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;\
  background: ${color.culture};
  border: ${color.oxfordBlue} solid 0.25rem;
  border-radius: 1rem;

  .event-card-details {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .event-card-title {
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.md};
    background: ${color.oxfordBlue};
    width: 100%;
    padding: 1rem;
    color: ${color.sunglow}
  }
  .event-card-description {
    padding: 1rem;
    font-weight: ${fontWeight.light};
    color: ${color.oxfordBlue};
    font-size: ${fontSize.sm};
  }

  .event-card-dates {
    margin-bottom: 1rem;
    padding: 1rem;
    font-weight: ${fontWeight.light};
    color: ${color.oxfordBlue};
    font-size: ${fontSize.sm};
  }

  .event-card-registration {
    padding: 1rem;
  }

`;
