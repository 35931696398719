import { graphql, useStaticQuery } from "gatsby";
import * as React from "react";
import { Layout } from "@components/layout";
import { PageHeader } from "@components/reusables/pageHeader";
import { EventContent } from "@components/events/eventContent";
import { HeadMetadata } from "@components/headMetadata";

const EventIndex = () => {
  const query = useStaticQuery(graphql`
    query eventPageQuery {
      pageHeaderQuery: strapiPageHeader(main_link: { url: { eq: "/events" } }) {
        id
        name
        description
        strapi_id
        coverImageGreyScale
        coverImageBlur
        coverImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, quality: 100)
            }
          }
        }
      }
    }
  `);

  const pageHeaderQuery = query.pageHeaderQuery;

  return (
    <Layout>
      <HeadMetadata location="Events"></HeadMetadata>
      <PageHeader
        name={pageHeaderQuery?.name ?? ""}
        description={pageHeaderQuery?.description ?? ""}
        coverImageGreyScale={pageHeaderQuery?.coverImageGreyScale ?? ""}
        coverImageBlur={pageHeaderQuery?.coverImageBlur ?? ""}
        coverImage={
          pageHeaderQuery?.coverImage?.localFile?.childImageSharp
            ?.gatsbyImageData ?? ""
        }
      ></PageHeader>
      <div className="content-wrapper">
        <EventContent></EventContent>
      </div>
    </Layout>
  );
};

export default EventIndex;
